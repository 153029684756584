<template>
    <a-drawer
        v-model:visible="visible"
        title="作业记录详情"
        :destroyOnClose="true"
        :closable="false"
        @close="handleCancel"
        :zIndex="99999"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="" :column="2">
            <a-descriptions-item label="提交时间">
                {{ detailData?.createdTime }}
            </a-descriptions-item>
            <a-descriptions-item label="操作人">
                {{ detailData?.creatorDisplayName }}
            </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="产成品信息" :column="2">
            <a-descriptions-item label="产成品条码">
                {{ detailData?.cjProductBarcode }}
            </a-descriptions-item>
            <a-descriptions-item label="产品编码">
                {{ detailData?.product?.productCode }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
                {{ detailData?.product?.productName }}
            </a-descriptions-item>
            <a-descriptions-item label="规格型号">
                {{ detailData?.product?.spec }}
            </a-descriptions-item>
            <a-descriptions-item label="生产批次号">
                {{ detailData?.product?.productionBatchNo }}
            </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="物料列表"></a-descriptions>
        <a-table
            :columns="columns"
            :data-source="detailData?.details"
            bordered
            size="small"
            :pagination="false"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>
            </template>
        </a-table>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiStepWorkDetail } from "@/api";

const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "物料编码",
        dataIndex: "materialCode",
    },
    {
        title: "物料名称",
        dataIndex: "materialName",
    },
    {
        title: "规格型号", 
        dataIndex: "materialSpec",
    },
    {
        title: "物料条码",
        dataIndex: "materialBarCode",
    },
    {
        title: "绑定数量",
        dataIndex: "count",
    },
];

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId"],

    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            detailData: null,
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getDetailData = async id => {
            let res = await apiStepWorkDetail(id);
            if (res.status === "SUCCESS") {
                state.detailData = res?.data;
                state.detailData.details = state.detailData?.details.map((item, index) => ({
                    ...item,
                    index,
                }));
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );
        return {
            ...toRefs(state),
            handleCancel,
            columns,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>

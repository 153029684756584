<template>
    <div class="step-homework">
        <DetailDrawer v-model:detailId="detailId" />
        <div class="back-btn">
            <a-button @click="handleGoBack">返回</a-button>
        </div>
        <a-descriptions title="">
            <a-descriptions-item label="工单号">{{ workInfo?.code }}</a-descriptions-item>
            <a-descriptions-item label="产品名称">{{ workInfo?.productName }}</a-descriptions-item>
            <a-descriptions-item label="产品编码">{{ workInfo?.productCode }}</a-descriptions-item>
            <a-descriptions-item label="工序名称">
                <Select
                    v-model:value="cjWorkOrderStepId"
                    :options="processOptions"
                    labelKey="name"
                    valueKey="id"
                    style="width: 80%"
                />
            </a-descriptions-item>
            <a-descriptions-item label="作业类型">物料绑定</a-descriptions-item>
            <a-descriptions-item label="完成数量">
                {{ workInfo?.finishedProductCount }} / {{ workInfo?.taskQuantity }}
            </a-descriptions-item>
        </a-descriptions>
        <div class="header">
            <span class="title">作业区域</span>
            <a-space direction="horizontal" :size="5">
                <div>
                    <a-input
                        ref="scanInputRef"
                        style="opacity: 0"
                        v-model:value="scanCode"
                        @blur="isOnScan = false"
                    />
                </div>
                <a-button type="primary" class="start" v-show="!isOnScan" @click="handleScan">
                    <span class="start">开始扫码</span>
                </a-button>
                <a-button type="primary" v-show="isOnScan" class="stop-btn">结束扫码</a-button>
            </a-space>
        </div>
        <div class="work-operate">
            <div class="product">
                <div class="title">
                    <span>产成品信息</span>
                    <a-button type="primary" danger @click="cjProductInfo = null">清除</a-button>
                </div>
                <a-descriptions title="" :column="1">
                    <a-descriptions-item label="产成品条码">
                        {{ cjProductInfo?.barcode }}
                    </a-descriptions-item>
                    <a-descriptions-item label="产品编码">
                        {{ cjProductInfo?.productCode }}
                    </a-descriptions-item>
                    <a-descriptions-item label="产品名称">
                        {{ cjProductInfo?.productName }}
                    </a-descriptions-item>
                    <a-descriptions-item label="规格型号">
                        {{ cjProductInfo?.spec }}
                    </a-descriptions-item>
                    <a-descriptions-item label="生产批次号">
                        {{ cjProductInfo?.productionBatchNo }}
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <div class="material">
                <div class="title">关键物料信息</div>
                <a-table
                    :columns="materialColumns"
                    :data-source="materialData"
                    bordered
                    size="small"
                >
                    <template #bodyCell="{ column, record, index }">
                        <template v-if="column.dataIndex === 'index'">
                            {{ record.index + 1 }}
                        </template>
                        <template v-if="column.dataIndex === 'operate'">
                            <a-space direction="horizontal" :size="5">
                                <a @click="() => handleDelete(index)">删除</a>
                            </a-space>
                        </template>
                    </template>
                </a-table>
            </div>
        </div>
        <a-descriptions title="作业记录"></a-descriptions>
        <div class="work-record">
            <a-table
                :columns="workRecordColumns"
                :data-source="workRecordData"
                bordered
                :pagination="pagination"
                @change="paginationChange"
                size="small"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => (detailId = record.id)">详情</a>
                            <a @click="() => handleReset(record.id)">重置</a>
                        </a-space>
                    </template>
                </template>
            </a-table>
            <div class="submit-btn">
                <div style="width: 200px">
                    <a-button type="primary" class="stop-btn" block @click="handleSubmit">
                        提交
                    </a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, watchEffect, watch } from "vue";
import { useRouter } from "vue-router";
import DetailDrawer from "./detailDrawer";
import Select from "@/components/Select";
import {
    apiMaterialDetail,
    apiCjFinishProductDetailByWorkordrIdAndBarCode,
    apiCjWorkOrderMaterialBindIndexData,
    apiSaveMaterial,
    apiStepWorkRecordList,
    apiReSetStepWork,
} from "@/api";
import { showMessage } from "@/utils/common";

const materialColumns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "物料编码",
        dataIndex: "itemCode",
    },
    {
        title: "物料名称",
        dataIndex: "itemName",
    },
    {
        title: "规格型号",
        dataIndex: "itemSpec",
    },
    {
        title: "物料条码",
        dataIndex: "itemBarcode",
    },
    {
        title: "操作",
        dataIndex: "operate",
    },
];

const workRecordColumns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "产成品SN码",
        dataIndex: "cjProductBarcode",
    },
    {
        title: "绑定物料数量",
        dataIndex: "bindCount",
    },
    {
        title: "提交时间",
        dataIndex: "createdTime",
    },
    {
        title: "操作人",
        dataIndex: "creatorDisplayName",
    },
    {
        title: "操作",
        dataIndex: "operate",
    },
];

export default defineComponent({
    components: {
        Select,
        DetailDrawer,
    },
    setup(props) {
        const { currentRoute, back } = useRouter();
        const state = reactive({
            processOptions: [],
            workInfo: null,
            detailId: null,
            cjWorkOrderStepId: undefined,
            isOnScan: false,
            scanInputRef: null,
            scanCode: "",
            cjProductInfo: null,
            materialData: [],
            workRecordData: [],
            isClickCancle: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getWorkRecord = async cjWorkStepId => {
            let res = await apiStepWorkRecordList({
                queryParam: {
                    cjWorkStepId,
                },
            });
            if (res.status === "SUCCESS") {
                state.workRecordData = res?.data?.recordList;
            }
        };

        watch(
            () => state.cjWorkOrderStepId,
            newVal => {
                if (newVal) getWorkRecord(newVal);
            }
        );

        const getDetailData = async row => {
            let res = await apiCjWorkOrderMaterialBindIndexData(state.workInfo?.id);
            if (res.status === "SUCCESS") {
                state.processOptions = res?.data?.steps;
            }
        };

        onMounted(() => {
            state.workInfo = currentRoute._value.query;
            getDetailData(state.workInfo?.id);
        });

        const handleDelete = index => {
            state.materialData.splice(index, 1);
            state.materialData = state.materialData
                .map((item, index) => ({
                    ...item,
                    index,
                }))
                .reverse();
        };

        const handleScan = () => {
            state.isClickCancle = false;
            state.isOnScan = true;
            state.scanInputRef.focus();
            state.scanCode = "";
        };

        const getCjFinishProductDetail = async () => {
            let res = await apiCjFinishProductDetailByWorkordrIdAndBarCode(
                state.workInfo?.id,
                state.scanCode
            );
            if (res.status === "SUCCESS" && res?.data) {
                state.cjProductInfo = res?.data;
            } else {
                showMessage("info", "该工单下未找到此产品");
            }
        };

        const getMaterialDetail = async () => {
            let res = await apiMaterialDetail(state.scanCode);
            if (res.status === "SUCCESS" && res?.data) {
                state.materialData.unshift({
                    ...res?.data,
                    index: state.materialData.length,
                });
            } else {
                showMessage("info", "未查询到此物料信息");
            }
        };

        // watch(
        //     () => state.isOnScan,
        //     newVal => {
        //         if (!newVal && !state.isClickCancle) {
        //             handleScan();
        //         }
        //     }
        // );

        // document.onclick = e => {
        //     if (!e.target.className.includes("start")) {
        //         state.isClickCancle = true;
        //     }
        // };

        const antiShake = (val, time) => {
            return setTimeout(() => {
                if (val) {
                    if (val == "submit") {
                        return handleSubmit();
                    }
                    if (state.cjProductInfo) {
                        getMaterialDetail();
                    } else {
                        getCjFinishProductDetail();
                    }
                    handleScan();
                }
            }, time);
        };

        watchEffect(onInvalidate => {
            const timer = antiShake(state.scanCode, 500);
            onInvalidate(() => clearTimeout(timer));
        });

        const handleReset = async id => {
            let res = await apiReSetStepWork(id);
            if (res.status === "SUCCESS") {
                getWorkRecord(state.cjWorkOrderStepId);
                showMessage("info", "重置成功");
            }
        };

        const handleSubmit = async () => {
            const { cjWorkOrderStepId, cjProductInfo, materialData } = state;
            if (!cjWorkOrderStepId) {
                return showMessage("info", "请选择工序");
            }
            if (!cjProductInfo) {
                return showMessage("info", "请扫描产成品条码");
            }
            if (materialData.length == 0) {
                return showMessage("info", "请扫描物料条码");
            }
            let res = await apiSaveMaterial({
                cjWorkOrderStepId,
                cjProductBarcode: cjProductInfo?.barcode,
                materialBarCodes: materialData.map(item => item.itemBarcode),
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                state.cjProductInfo = null;
                state.materialData = [];
                getWorkRecord(cjWorkOrderStepId);
            }
        };

        return {
            ...toRefs(state),
            materialColumns,
            handleDelete,
            paginationChange,
            handleScan,
            handleGoBack: () => back(-1),
            workRecordColumns,
            handleReset,
            handleSubmit,
        };
    },
});
</script>

<style lang="less" scoped>
.stop-btn {
    background: #67c23a;
    border-color: #67c23a;
}
.hideinput {
    width: 80%;
    position: absolute;
    left: 0;
    z-index: -1;
    opacity: 0;
}
.step-homework {
    .back-btn {
        margin: 16px 0;
        text-align: right;
    }

    padding: 16px;
    .header {
        margin: 16px 0;
        display: flex;
        justify-content: space-between;
        .title {
            font-weight: bold;
            font-size: 16px;
        }
    }
    .work-operate {
        display: flex;
        margin-bottom: 16px;

        .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }
        .product {
            padding: 16px;
            flex: 1;
            background: #8080801f;
            margin-right: 8px;
        }
        .material {
            padding: 16px;
            flex: 2;
            background: #8080801f;
        }
    }
    .work-record {
        .submit-btn {
            margin-top: 16px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
</style>
